pre {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';;
}

.ant-layout-header {
  display: flex;
  justify-content: start;

  > .logo-title {
    margin-right: 50px;
  }

  > .header-menus {
    flex-grow: 1;
  }
}

.search-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 20px;

  > dl.search-item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    height: 32px;

    &.search-item-1x {
      width: 25%;
    }

    &.search-item-2x {
      width: 50%;
    }

    &.search-item-3x {
      width: 75%;
    }

    &.search-item-4x {
      width: 100%;
    }

    &.search-item-full {
      width: 100%;
    }

    &.search-item-half {
      width: 49.99%;
    }

    > dt {
      position: relative;
      min-width: 120px;
      text-align: right;
      line-height: 31px;
      margin-right: 12px;
    }

    > dd {
      flex-grow: 1;
      margin-bottom: 0px;
    }
  }

  > .search-btns {
    width: 100%;
    display: block;
    text-align: center;
  }
}

// 红色背景行
.row-danger {
  background-color: #FEF0F0;
  color: #888;
}
.row-warning {
  background-color: #FDF6EC;
}
.row-success {
  background-color: #F0F9EB;
}

.ant-btn.ant-btn-link {
  padding: 4px 6px;
}


.tag-red {
  display: inline-block;
  color: white;
  background-color: #FF5500;
  border-radius: 8px;
  margin-left: 4px;
  line-height: 13px;
  font-size: 12px;
  padding: 2px 6px;
  margin-left: 5px;
}

.product-detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > .product-detail-item {
    display: block;
    width: 100%;
    flex-grow: 1;

    &.product-detail-item-half {
      display: inline-block;
      width: 49.9%;
    }

    > dt {
      color: rgb(141, 129, 151);
      font-weight: bold;
      font-size: 14px;
      padding-bottom: 4px;
      padding-left: 6px;
      border-bottom: 1px solid #EEE;
      margin-bottom: 6px;
    }

    > dd {
      color: #333;
      font-size: 13px;
      margin-bottom: 0px;
    }
  }
    
  + .product-detail-item {
    margin-left: 15px;
    margin-top: 15px;
  }
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

// 页面头部LOGO
.page-header-logo {
  max-height: 32px;
  max-width: 50px;
}