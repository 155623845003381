
@import '~@/assets/less/variables.less';

// 颜色选择器
.color-picker {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 26px;

  &.color-picker-large {
  }
  &.color-picker-default {
  }
  &.color-picker-small {
    line-height: 20px;

    > .color-picker-color-box {
      top: 3px;
    }
  }

  > .color-picker-input {
    border: none;
    width: 100%;
    border-radius: 4px;
    padding-right: 30px;
    padding-left: 10px;
  }

  > .color-picker-color-box {
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

    > .color-picker-color-value {
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 3px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2) inset;
    }
  }

  // 下拉框
  > .color-picker-dropdown {
    position: absolute;
    z-index: 9999;
    background-color: white;
    border: 1px solid #eee;
    top: calc(100% + 2px);
    right: 0px;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transform: translateY(20px);
    transition: 0.15s;
    visibility: hidden;
    will-change: transform, opacity;

    &.color-picker-dropdown-show {
      visibility: visible;
      transform: translateY(0px);
      opacity: 1;
    }

    &.color-picker-dropdown-top {
      top: initial;
      bottom: calc(100% + 4px);
      transform: translateY(-20px);

      &.color-picker-dropdown-show {
        visibility: visible;
        transform: translateY(0px);
        opacity: 1;
      }
    }

    > hr {
    }

    > .color-picker-color {
      display: flex;
      flex-direction: column;
      width: 50px;
      height: 50px;
      margin: 12px;
      margin-left: 30px;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

      > .color-picker-oldcolor {
        position: relative;
        flex-grow: 1;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        &:before {
          position: absolute;
          color: #bbb;
          content: '旧';
          line-height: 25px;
          left: -22px;
          font-size: 12px;
        }
      }
      > .color-picker-newcolor {
        position: relative;
        flex-grow: 1;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &:before {
          content: '新';
          position: absolute;
          color: #bbb;
          line-height: 25px;
          left: -22px;
          font-size: 12px;
        }
      }
    }

    > .color-picker-disk {
      user-select: none;
      position: relative;
      width: 290px;
      height: 190px;

      > .color-picker-disk-point {
        position: absolute;
        cursor: pointer;
        top: -6px;
        left: -6px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 1px solid white;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
        background-color: rgba(255, 255, 255, 0.1);

        &:before {
          position: absolute;
          top: -15px;
          left: -15px;
          width: 43px;
          height: 43px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #000, transparent), linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
      }
    }

    > .color-picker-slider-disk {
      position: absolute;
      top: 213px;
      left: 100px;
      width: calc(100% - 115px);
    }

    > .color-picker-slider-alpha {
      position: absolute;
      top: 238px;
      left: 100px;
      width: calc(100% - 115px);
    }

    // 历史列表
    > .color-picker-history {
      display: flex;
      flex-wrap: wrap;

      > .color-picker-history-item {
        position: relative;
        cursor: pointer;
        display: inline-block;
        margin: 0px 4px 8px 4px;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

        > .color-picker-history-item-color {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 3px;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2) inset;
        }
      }
    }

    // 选择器底部
    > .color-picker-footer {
      display: flex;
      width: 290px;

      > .color-picker-footer-text {
        flex-grow: 1;
        display: flex;
        width: 100%;
        align-items: center;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 4px;

        > input {
          border: none;
          display: inline-block;
          line-height: 20px;
          flex-shrink: 1;
          padding-left: 5px;
          font-size: 12px;
          width: 100%;
        }

        > .color-picker-footer-text-type {
          display: inline-block;
          cursor: default;
          width: 30px;
          line-height: 24px;
          text-align: center;
          flex-shrink: 0;
          color: #aaa;
        }

        > .color-picker-footer-text-changetype {
          display: inline-block;
          cursor: pointer;
          width: 26px;
          line-height: 22px;
          border-radius: 4px;
          text-align: center;
          flex-shrink: 0;
          color: @primary-color;
          transition: 0.2s;
          margin-right: 2px;

          &:hover {
            color: white;
            background-color: @primary-color;
          }
        }
      }

      > .color-picker-footer-tools {
        text-align: right;
        flex-shrink: 0;

        > .color-picker-footer-tools-confirm {
          border: 1px solid #d9d9d9;
          background-color: white;
          border-radius: 4px;
          font-size: 12px;
          height: 27px;
          line-height: 24px;
          padding-left: 8px;
          padding-right: 8px;
          transition: 0.2s;

          &:hover {
            color: white;
            background-color: @primary-color;
          }
        }

        > .color-picker-footer-tools-clear {
          border: none;
          background-color: white;
          border-radius: 4px;
          color: @primary-color;
          font-size: 12px;
          height: 27px;
          line-height: 24px;
          padding-left: 6px;
          padding-right: 6px;
          margin-right: 4px;
          transition: 0.2s;

          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
}
