@import './variables.less';
@import '../mixin.less';

body.mobile {

  // 滚动条样式

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: block;
  }
  ::-webkit-scrollbar-button:vertical:start:increment,
  ::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
  ::-webkit-scrollbar-track:vertical,
  ::-webkit-scrollbar-track:horizontal,
  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal,
  ::-webkit-scrollbar-track:vertical,
  ::-webkit-scrollbar-track:horizontal,
  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    border: transparent;
    border-style: solid;
  }
  ::-webkit-scrollbar-track:vertical::-webkit-scrollbar-track:horizontal {
    .backgroundClip();
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    .backgroundClip();
    min-height: 28px;
    padding-top: 100;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 30%, rgba(0, 0, 0, 0.2) 31%,rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.0) 71%);
    border-radius: 4px;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.5);
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  ::-webkit-scrollbar-track:vertical,
  ::-webkit-scrollbar-track:horizontal,
  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    border-width: 0;
  }
  ::-webkit-scrollbar-track:hover {
    background: rgba(0, 0, 0, 0.05);
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  ::-webkit-scrollbar-track:active {
    background: rgba(0, 0, 0, 0.05);
    box-shadow: none;
    -webkit-box-shadow: none;
  }

}