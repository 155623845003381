@import '../variables.less';
@import './variables.less';
@import './basic.less';
@import './component.less';

body.mobile {
  
  #app {

    .layout-sider-shadow {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.1);
      transition: 0.15s;
      opacity: 0;
      visibility: hidden;
      z-index: 99;

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    .logo {
      display: none;
    }
    
    .ant-layout-sider {
      position: fixed;
      top: 50px;
      left: 0px;
      z-index: 9999;
      height: 100vh;
      padding-bottom: 0px;
      transition: 0.15s;
      visibility: hidden;
      opacity: 0.0;
      transform: translateX(-250px);
      box-shadow: 25px 5px 25px 0px rgba(0,0,0,0.2);

      &.ant-layout-sider-hidden {
        transform: translateX(0px);
        opacity: 1;
        visibility: visible;
      }

      .logo {

        > .logo-img {
          width: 34px;
          height: 34px;
          margin: 6px 14px 6px 0;
        }
      }
    }
  
    .ant-layout-header {
      justify-content: space-between;

      > .ant-layout-header-left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 15px;

        > .logo-img {
          width: 34px;
          height: 34px;
          background-color: white;
          padding: 2px;
          border-radius: 50%;
        }
      }

      > .ant-layout-header-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-right: 5px;

        > .neu-page-tabs {
          display: none;
        }

        > .layout-shortcut-main-menu {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;
        }

        // 快捷菜单
        > .layout-shortcut-menu {
          flex-shrink: 0;
          flex-grow: 0;
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          margin-left: 15px;
          margin-right: 5px;
      
          > .layout-userinfo {
            display: inline-block;
            line-height: 32px;
            color: #333333;
            font-size: 16px;

            > .layout-userinfo-username {
              display: none;
            }
      
            > img {
              width: 36px;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .ant-layout-content {
      padding: 0px;

      > .app-page {
        position: relative;
        top: 0px;
        left: 0px;
        width: auto;
        height: auto;
      }
    }
  }
}