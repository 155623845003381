
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  color: var(--icon-color) !important;
  fill: currentColor;
  overflow: hidden;
  transition: 0.12s;

  &:hover {
    font-weight: bold;
    color: var(--icon-color-hover) !important;
  }
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
