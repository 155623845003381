
:deep(.ant-table.ant-table-small) {
  > .ant-table-content {
    .ant-table-body {
      margin: 0px;

      > table {
        > thead.ant-table-thead {
          > tr {
            > th {
              padding: 4px;
            }
          }
        }

        > tbody.ant-table-tbody {
          > tr.ant-table-row {
            > td {
              padding: 4px;
            }
          }
        }
      }
    }

    table.ant-table-fixed {
      > thead.ant-table-thead {
        > tr {
          > th {
            padding: 4px;
          }
        }
      }

      > tbody.ant-table-tbody {
        > tr.ant-table-row {
          > td {
            padding: 4px;
          }
        }
      }
    }
  }
}
