
.rules-editor {
  word-break: break-all;
  white-space: normal;

  > .rule-item {
    display: inline-block;
    margin-bottom: 8px;
    width: auto;

    &.enable {
      display: flex;
      width: 100%;
    }

    > .rule-item-label {
      user-select: none;
      vertical-align: middle;
      line-height: 22px;
      padding-left: 8px;
      flex-grow: 1;
      white-space: nowrap;
    }
    > .rule-item-value {
      flex-grow: 200;
    }
  }
}
