
// 页面切换动画

.page-change-enter-active,
.page-change-leave-active {
  transition: all 0.25s ease-out;
}

.page-change-enter-from {
  opacity: 0;
  transform: translateX(20px);
}

.page-change-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

.ant-layout-content {
  position: relative;
  overflow-x: hidden;

  > .app-page {

    &.page-change-enter-active {
      position: absolute;
      top: 20px;
      left: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
    }
  }
}


// 登录页动画

@keyframes login-header-light {
  0% {
    opacity: 0;
    transform: translateX(-80px);
  }
  40% {
    opacity: 1;
    transform: translateX(60px);
  }
  60% {
    opacity: 0;
    transform: translateX(60px);
  }
  100% {
    opacity: 0;
    transform: translateX(-80px);
  }
}

@keyframes login-panel-light {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.8;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}