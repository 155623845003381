
body.mobile {

  // ———————————— 页面卡片 ————————————

  .page-panel {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }

    + .page-panel {
      margin-top: 0px;
    }
    
    // 卡片头部
    > .page-panel-header {
      flex-wrap: wrap;
      padding: 15px;

      // 卡片标题
      > .page-panel-title {
        display: block;
        width: 100%;
        margin-bottom: 15px;
      }

      // 卡片筛选栏
      > .page-panel-header-filter {
        width: 100%;
      }

      > button {
        padding: 2px 8px;

        + button {
          margin-right: 10px !important;
        }
      }

      // 卡片工具栏（右侧图标）
      > .page-panel-tools {

        > .page-panel-tool {

        }
      }
    }

    // 卡片内容
    > .page-panel-content {

      > .data-filter {
      }

      .ant-table {
        overflow-x: auto;

        > .ant-table-container {
          width: 1000px;
          max-width: 1000px;
        }
      }
    }
  }


  // ———————————— 单行筛选栏 ————————————

  .data-filter-row {
    flex-direction: column;
    width: 100%;

    // 筛选项
    > .data-filter-item {
      width: 100%;
      margin-top: 10px;

      + .data-filter-item {
        margin-left: 0px;
      }

      // 筛选项标签
      > .data-filter-item-label {
      }

      // 筛选项内容
      > .data-filter-item-value {
        flex-grow: 1;
        margin-left: 0px;

        > div {
          width: 100%;
        }
      }
    }

    // 提交按钮
    > .data-filter-btns {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 0px;

      > button {
        width: 100%;
      }
    }
  }


  // ———————————— 多行筛选栏 ————————————

  .data-filter {

    // 筛选项查询按钮组
    > .data-filter-btns {
      width: 100%;
      justify-content: center;

      > button {

        + button {
        }
      }
    }

    // 筛选项
    > .data-filter-item {
      width: 100%;
      margin-left: 0px;

      // 筛选项标签
      > .data-filter-item-label {

        &:after {
        }
      }

      // 筛选项内容
      > .data-filter-item-value {

        > .ant-select,
        > .ant-picker {
          width: 100%;
        }
      }
    }
  }

  // ———————————— 新增编辑界面 ————————————

  // 表单项
  .form-item-mixin() {
    width: 100%;
    margin-right: 10px;

    &.col-full {
      margin-right: 10px;
    }

    &.col-half {
      margin-right: 10px;
    }

    > .ant-form-item-label {
    }

    > .ant-form-item-control {
    }
  }

  .form-panel-content-mixin() {
    width: 100%;
  }

  .form-panel {
    position: relative;
    height: calc(100% - 60px);

    > .ant-spin {

      &.ant-spin-spinning {
      }

      > .ant-spin-text {
      }
    }

    > .form-panel-content {
      .form-panel-content-mixin();
      margin-right: 0px;

      > .form-item {
        .form-item-mixin();
      }
    }

    // 表单按钮
    > .form-panel-btns {
      width: 100%;
      align-self: stretch;
      justify-content: space-between;
      padding-right: 10px;

      > button {
        width: 100%;
        height: 40px;
      }
    }

    > .ant-form {
      margin-right: 0px;

      > .form-panel-content {
        .form-panel-content-mixin();

        > .form-item {
          .form-item-mixin();
        }
      }
    }
  }


  // ———————————— 多行筛选栏 ————————————

  .filter-panel {

    > dl.filter-item {

      &.filter-item-full {
      }

      &.filter-item-half {
      }

      > dt.filter-item-label {
      }

      > dd.filter-item-value {
      }
    }

    > .filter-btns {
    }
  }


  // ———————————— 弹出框 ————————————

  .ant-drawer-content-wrapper {
    width: 100vw !important;

    .ant-drawer-body {
      padding: 15px;
      padding-right: 5px;


    }
  }

}