.backgroundClip() {
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -ms-background-clip: padding-box;
  background-clip: padding-box;
}
// flex居中
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
// 绝对定位自动撑满父级非静态元素
.absulute-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
// 移动端样式，传入的styles只在移动端生效
.mobile-style(@styles) {
  body.mobile &{
    @styles();  
  }
}