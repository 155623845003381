
@import '~@/assets/less/variables.less';

.editor-quill {
}

:deep(.richtext-editor) {
  background-color: #fafafa !important;

  .w-e-toolbar {
    background-color: #fafafa !important;
    border: none !important;
    margin-bottom: 2px;
  }

  .w-e-text-container {
    background-color: #f7f9fc;
    border: 1px solid transparent !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: 0.2s;

    &:hover {
      border: 1px solid fadeout(@primary-color, 20%) !important;
    }

    &:focus-within {
      box-shadow: 0px 0px 0px 2px rgba(77, 140, 228, 0.3);
    }

    > .w-e-text {
      &:focus {
        box-shadow: 0px 0px 0px 2px rgba(77, 140, 228, 0.3);
      }
    }
  }
}
