@font-face {
  font-family: "iconfont";
  src: url('./iconfont.woff?t=05e5ef67b7bbb72fd90b66cbe8043754') format('woff2'),
  url('./iconfont.woff?t=05e5ef67b7bbb72fd90b66cbe8043754') format('woff'), /* chrome、firefox */
  url('./iconfont.ttf?t=05e5ef67b7bbb72fd90b66cbe8043754') format('truetype'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bianji:before {
  content: "\e001";
}
.icon-cuowu:before {
  content: "\e002";
}
.icon-chense-shizhi-tai-icon:before {
  content: "\e003";
}
.icon-dingbu:before {
  content: "\e004";
}
.icon-caishanshouqi:before {
  content: "\e005";
}
.icon-mima-shuru2:before {
  content: "\e006";
}
.icon-yonghuming-shuru2:before {
  content: "\e007";
}
.icon-jitonggongju:before {
  content: "\e008";
}
.icon-shouqi:before {
  content: "\e009";
}
.icon-yanzhengma2:before {
  content: "\e00a";
}
.icon-AI-tupianguanli:before {
  content: "\e00b";
}
.icon-shaixuan:before {
  content: "\e00c";
}
.icon-zhengque:before {
  content: "\e00d";
}
.icon-shuaxin:before {
  content: "\e00e";
}
.icon-jianqiangguanli-btn-n2:before {
  content: "\e00f";
}
.icon-guanbi:before {
  content: "\e010";
}
.icon-lishiyeqian:before {
  content: "\e011";
}
.icon-shizhi:before {
  content: "\e012";
}
.icon-jitongguanli:before {
  content: "\e013";
}
.icon-shanshu:before {
  content: "\e014";
}
.icon-quanjuxiaosuo:before {
  content: "\e015";
}
.icon-xiaosuo:before {
  content: "\e016";
}
.icon-tongzhi:before {
  content: "\e017";
}
.icon-guanli:before {
  content: "\e018";
}
.icon-fangtaiquanping:before {
  content: "\e01a";
}
.icon-guoji2:before {
  content: "\e01b";
}
.icon-xiaosuo-guanbi:before {
  content: "\e01c";
}
.icon-shouye-1:before {
  content: "\e01d";
}
