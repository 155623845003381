
.operte-edit-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -20px;

  > .operte-edit-item {
    margin-right: 20px;

    &.operte-edit-item-1x {
      width: calc(50% - 20px);

      + .operte-edit-item-1x {
      }
    }

    &.operte-edit-item-2x {
      width: calc(100% - 20px);
    }

    &.operte-edit-item-3x {
      width: calc(100% - 20px);
    }

    &.operte-edit-item-4x {
      width: calc(100% - 20px);
    }
  }
}
