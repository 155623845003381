
@import '~@/assets/less/variables.less';

.box-editor {
  position: relative;
  height: 72px;
  margin-top: 10px;
  margin-bottom: 10px;

  // &:before {
  //     content: '';
  //     position: absolute;
  //     top: 24px;
  //     left: 32%;
  //     width: 36%;
  //     height: 12px;
  //     border: 1px solid #CCC;
  // }

  > div {
    position: absolute;
    height: 24px;
    padding: 0px;
    border-radius: 4px;

    > .box-editor-label {
      display: inline-block;
      vertical-align: top;
      line-height: 24px;
      margin-right: 5px;
    }

    > .box-editor-unit {
      position: absolute;
      top: 0px;
      right: 12px;
      color: #bbb;
      font-size: 12px;
      display: inline-block;
      vertical-align: top;
      line-height: 24px;
      margin-left: 5px;
    }

    > input {
      background-color: #f7f9fc;
      border: 1px solid #f7f9fc;
      border-radius: 4px;
      height: 24px;
      width: calc(100% - 25px);
      vertical-align: top;
      line-height: 18px;
      padding-right: 20px;
      padding-left: 5px;
      color: #666;
      font-size: 12px;
      transition: 0.3s;

      &:hover {
        border-color: fadeout(@primary-color, 20%);
        border-right-width: 1px !important;
      }

      &:focus {
        box-shadow: 0px 0px 0px 2px fadeout(@primary-color, 70%);
      }
    }
  }

  > .box-editor-top {
    top: 0px;
    left: 30%;
    width: 40%;
  }

  > .box-editor-right {
    top: 24px;
    right: 0%;
    width: 30%;
  }

  > .box-editor-bottom {
    bottom: 0px;
    right: 30%;
    width: 40%;
  }

  > .box-editor-left {
    top: 24px;
    left: 0%;
    width: 30%;
  }
}
