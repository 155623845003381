
.color-picker-slider {
  user-select: none;
  position: relative;
  display: block;
  width: 100%;
  height: 12px;
  border-radius: 3px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

  &:before {
    content: '';
  }

  > .color-picker-slider-content {
    user-select: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 3px;

    > .color-picker-slider-bar {
      user-select: none;
      position: absolute;
      cursor: default;
      top: -2px;
      left: -7px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #f8f8f8;
      box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.4);
    }
  }
}
