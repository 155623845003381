
@import '@/assets/less/variables.less';

// 标签背景色
@page-tabs-item-bg: #f8f8f8;
// 标签圆角
@page-tabs-item-border-radius: 2px;
// 标签高度
@page-tabs-item-height: 36px;
// 标签按钮大小
@page-tabs-btn-size: 32px;
// 最小标签长度
@page-tabs-item-min-width: 120px;
// 标签颜色
@page-tabs-item-color: #777777;
// 图标颜色
@page-tabs-icon-color: #b2b2b2;
// 动画时长
@page-tabs-animation-time: 0.12s;
// 动画时长
@page-tabs-sider-width: 15px;

// 页面标签组件
.neu-page-tabs {
  position: relative;
  display: flex;
  height: @page-tabs-item-height;
  line-height: @page-tabs-item-height;

  > .page-tab-action {
    user-select: none;
    cursor: pointer;
    background-color: #d8d8d8;
    color: white;
    padding: 0px 4px;
    border-radius: 3px;
    font-family: '宋体';
    font-style: normal;
    font-size: 12px;
    transition: @page-tabs-animation-time;

    &:hover {
      background-color: @primary-color;
    }
  }

  &.scroll-min {
    > .page-tab-action-left {
      transform: translateX(-@page-tabs-sider-width);
      visibility: hidden;
      opacity: 0;
    }
    > .page-tab-content {
      margin-left: -@page-tabs-sider-width;
      &:before {
        box-shadow: 4px 0px 3px 3px transparent;
      }
    }
  }
  .page-tab-action-left {
    visibility: visible;
    opacity: 1;

    &:hover {
      background-color: #c2c0c0;
    }
  }

  &.scroll-max {
    > .page-tab-action-right {
      transform: translateX(@page-tabs-sider-width);
      visibility: hidden;
      opacity: 0;
    }
    > .page-tab-content {
      margin-right: -@page-tabs-sider-width;
      &:after {
        box-shadow: 4px 0px 3px 3px transparent;
      }
    }
  }
  .page-tab-action-right {
    visibility: visible;
    opacity: 1;

    &:hover {
      background-color: #c2c0c0;
    }
  }

  &:hover {
    > .page-tab-content {
      > .page-tab-progress {
        opacity: 1;
      }
    }
  }

  // 历史记录
  .ant-dropdown-link {
    color: @page-tabs-icon-color;

    &:hover {
      color: @primary-color;
    }
    .page-tab-history {
      cursor: pointer;
      user-select: none;
      flex-shrink: 0;
      flex-grow: 0;
      display: inline-block;
      width: @page-tabs-btn-size;
      height: @page-tabs-btn-size;
      font-size: 24px;
      text-align: center;
      font-style: normal;
      margin-left: 15px;
      margin-right: 15px;
      color: @page-tabs-icon-color;
      flex-shrink: 0;
      flex-grow: 0;
      transition: @page-tabs-animation-time;
      fill: @page-tabs-icon-color;
    }
  }

  // 标签列表
  > .page-tab-content {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0px 10px;
    height: calc(@page-tabs-item-height + 10px);
    transition: @page-tabs-animation-time;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0px;
      left: -1px;
      width: 30px;
      height: @page-tabs-item-height;
      box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0px;
      right: -1px;
      width: 30px;
      height: @page-tabs-item-height;
      box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
    }

    // 滚动条
    > .page-tab-progress {
      position: absolute;
      bottom: 5px;
      left: 0px;
      height: 3px;
      width: 0px;
      background-color: #d8d8d8;
      border-radius: 2px;
      opacity: 0;
      transition: 0.06s;
    }

    > .page-tab-list {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 0px;
      padding-left: 0px;
      flex-shrink: 1;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 0px;
      height: calc(@page-tabs-item-height + 10px);
      padding-top: 1px;

      > .page-tab-item {
        cursor: pointer;
        user-select: none;
        position: relative;
        list-style: none;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: @page-tabs-item-bg;
        transition: @page-tabs-animation-time;
        border-radius: @page-tabs-item-border-radius;
        height: calc(@page-tabs-item-height - 2px);
        border-left: 3px solid @page-tabs-item-bg;

        &.active {
          cursor: default;
          border-left-color: #95a0f8;
          background-color: white;
          box-shadow: 0px 0px 0px 1px #ebecff, 0px 4px 6px 0px rgba(40, 59, 187, 0.08);

          > .page-tab-item-label {
            color: #396fff;
          }
        }

        + .page-tab-item {
          margin-left: 15px;
        }

        &:hover {
          > .page-tab-item-remove {
            opacity: 1;
          }
        }

        .page-tab-item-label {
          user-select: none;
          flex-shrink: 1;
          flex-grow: 1;
          display: inline-block;
          width: 100%;
          min-width: @page-tabs-item-min-width;
          white-space: nowrap;
          text-align: center;
          color: @page-tabs-item-color;
          padding: 0px 0px 0px 16px;
          height: calc(@page-tabs-item-height - 2px);
          line-height: calc(@page-tabs-item-height - 4px);
        }

        > .page-tab-item-remove {
          cursor: pointer;
          flex-shrink: 0;
          flex-grow: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 0px;
          font-size: 18px;
          width: 18px !important;
          font-style: normal;
          text-align: center;
          opacity: 0;
          width: @page-tabs-btn-size;
          height: @page-tabs-btn-size;
          transition: @page-tabs-animation-time;
          border-radius: @page-tabs-item-border-radius;
          color: @page-tabs-icon-color;
          padding-right: 10px;

          &:hover {
            color: #396fff;
          }
        }
      }
    }
  }
}
