
.ant-table-wrapper {

  .ant-spin-container {

    > .ant-pagination {

      &.ant-table-pagination-right {
        margin-right: 20px;
      }
    }
  }
}