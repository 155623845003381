
.version-chart {
  position: relative;
  width: 700px;
  height: 700px;

  > .g6-minimap {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 150px;
    height: 100px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: content-box;

    > .g6-minimap-container {
      border: 1px solid #ddd;

      > .g6-minimap-viewport {
        box-shadow: rgb(0 0 0 / 30%) 0px 0px 0px 1000px;
        border-radius: 5px;
      }
    }
  }
}
